<template>
  <tr :class="odd ? 'odd' : 'even'">
    <td class="text-center">
      {{ dataHora }}
    </td>

    <td class="text-left">
      <FieldRouteTo
        :view-link="editLink"
        :text="descricao"
        tooltip="Editar Página"
      />
    </td>

    <td class="text-left">
      <FieldRouteTo
        :view-link="viewLink"
        text="Visualizar"
        tooltip="Visualizar Página"
      />
    </td>
  </tr>
</template>

<script>
import slug from 'slug'
import moment from 'moment-timezone'
import FieldRouteTo from '@/components/fields/FieldRouteTo.vue'
import { ROUTE_LEARN_PAGES_VIEW, ROUTE_LEARN_PAGES_EDIT } from '@/constants'

export default {
  components: {
    FieldRouteTo
  },
  props: {
    odd: {},

    id: {},
    descricao: {},
    updatedAt: {}
  },

  computed: {
    dataHora () {
      return moment.unix(this.updatedAt).format('DD/MM/YY HH:mm')
    },

    viewLink () {
      const id = this.id
      const descricao = slug(this.descricao, '-')
      return { name: ROUTE_LEARN_PAGES_VIEW, params: { id, descricao } }
    },

    editLink () {
      const id = this.id
      return { name: ROUTE_LEARN_PAGES_EDIT, params: { id } }
    }
  }
}
</script>

<style scoped>
  tr.odd>td {
    background-color: #0094d90f
  }
</style>
