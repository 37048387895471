<template>
  <v-card
    class="elevation-0"
    outlined
  >
    <HeaderCard>
      <v-icon
        class="mr-1"
        size="medium"
      >
        mdi-school-outline
      </v-icon>
      <b>{{ 'title.listLearn'.translate() }}</b>

      <v-spacer />

      <v-btn
        x-small
        outlined
        color="white"
        @click="handleNewLearnPage"
      >
        <v-icon small>
          mdi-plus
        </v-icon>
        Adicionar
      </v-btn>
    </HeaderCard>

    <v-card-text style="padding: 0;">
      <v-form
        ref="form"
        autocomplete="off"
      >
        <v-container
          grid-list-xl
          class="mx-3"
        >
          <v-layout wrap>
            <v-flex
              xs6
              sm6
              md6
              lg6
            >
              <FilterText
                label="filter.learnDescriptionArg"
                v-model="filters.descricao"
              />
            </v-flex>

            <v-flex
              xs6
              sm6
              md6
              lg6
            >
              <FilterText
                label="filter.learnSearchArg"
                v-model="filters.busca"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>

      <v-data-table
        :headers="headers"
        :items="rows"
        :loading="isLoading"

        :options.sync="options"
        :server-items-length="count"
        :mobile-breakpoint="0"

        disable-pagination
        hide-default-footer

        class="elevation-0"
        item-key="id"
      >
        <template #item="{ item, index }">
          <LearnPageSearchItem
            :id="item.id"
            :descricao="item.descricao"
            :updated-at="item.updated_at"
            :odd="index % 2"
          />
        </template>

        <template #footer>
          <v-divider class="my-2" />

          <v-pagination
            v-model="page"
            :length="Math.ceil(count / 12)"
            :total-visible="7"
            v-if="Math.ceil(count / 12) > 1"
            @input="$vuetify.goTo(0, { duration: 850, offset: 0, easing: 'easeInOutCubic' })"
          />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import HeaderCard from '@/components/HeaderCard'
import FilterText from '@/components/filters/FilterText'
import LearnPageSearchItem from './LearnPageSearchItem'
import { mapState } from 'vuex'

import {
  STATUS_LOADING,
  ROUTE_LEARN_PAGES_NEW
} from '@/constants'

export default {
  components: {
    FilterText,
    HeaderCard,
    LearnPageSearchItem
  },

  data () {
    const qry = { ...this.$route.query }
    const page = isNaN(qry.page) ? 1 : Number(qry.page)
    const sortBy = hasQryVal(qry, 'sortBy') ? qry.sortBy : 'descricao'
    const orderDesc = String(qry.order || '').trim() === 'DESC'

    const filters = {
      busca: hasQryVal(qry, 'busca') ? qry.busca : undefined,
      descricao: hasQryVal(qry, 'descricao') ? qry.descricao : undefined
    }

    const options = {
      sortBy: [sortBy],
      sortDesc: [orderDesc],
      itemsPerPage: 12
    }

    return {
      page,
      options,
      filters
    }
  },

  computed: {
    ...mapState({
      rows: ({ learnPages }) => learnPages.rows,
      count: ({ learnPages }) => learnPages.count,
      isLoading: ({ learnPages }) => learnPages.status === STATUS_LOADING
    }),

    headers () {
      return [
        { text: 'Data/Hora', align: 'center', sortable: true, value: 'updated_at' },
        { text: 'Descrição', align: 'start', sortable: true, value: 'descricao' },
        { text: '', align: '', sortable: false, value: '' }
      ]
    }
  },

  methods: {
    search () {
      const query = {
        page: this.page,
        order: this.options.sortDesc[0] !== false ? 'DESC' : 'ASC',
        sortBy: this.options.sortBy.length ? this.options.sortBy[0] : 'descricao'
      }

      const fltrs = { ...this.filters }
      if (hasQryVal(fltrs, 'busca')) query.busca = fltrs.busca
      if (hasQryVal(fltrs, 'descricao')) query.descricao = fltrs.descricao
      this.$store.dispatch('searchLearnPages', query)
      this.$router.replace({ query }).catch(() => ({}))
    },

    handleNewLearnPage () {
      this.$router.push({ name: ROUTE_LEARN_PAGES_NEW })
    }
  },

  watch: {
    page () {
      this.search()
    },

    filters: {
      deep: true,
      handler () {
        this.page = 1
        this.search()
      }
    },

    options: {
      deep: true,
      handler () {
        this.search()
      }
    }
  }
}

const hasQryVal = (qry, val) => (
  qry[val] !== undefined && String(qry[val]).trim()
)

</script>
