<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <router-link
        :to="viewLink"
        target="_blank"
      >
        <span v-on="on">
          {{ text }}
        </span>
      </router-link>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    tooltip: {},
    viewLink: {},
    text: {}
  }

}
</script>
